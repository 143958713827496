<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="formCheck"
      label-width="120px"
      class="login-form"
    >
      <el-form-item prop="name" label="商品分类名称">
        <el-input
          placeholder="请输入商品分类名称"
          style="max-width:240px;"
          v-model="ruleForm.name"
        ></el-input>
      </el-form-item>
      <el-form-item label="商品分类主图">
        <div style="padding-top:12px;">
          <UploadImg v-model="ruleForm.icon" prefix="product/classify/icon"></UploadImg>
        </div>
      </el-form-item>
      <el-form-item prop="order" label="排序">
        <el-input-number
          v-model="ruleForm.order"
          :min="1"
          :max="Number.MAX_SAFE_INTEGER"
          label="请输入排序"
        ></el-input-number>
      </el-form-item>
      <div class="flex flex-justify-around" style="padding-top:30px;">
        <div>
          <el-button
            @click="hide('cancel')"
            style="width: 80px;"
            type="primary"
            plain
            >取消</el-button
          >
        </div>
        <div>
          <el-button @click="submitForm()" style="width: 80px;" type="primary"
            >提交</el-button
          >
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
import _ from "lodash";
import { IMG_URL_PRE } from "@/config";
import { orgcatAdd, orgcatEdit } from "@/service/item";
import UploadImg from "@/components/upload/UploadImg.vue";
export default {
  components: {
    UploadImg
  },
  props: ["form", "popTitle"],
  data() {
    return {
      rules: {
        name: [{ required: true, message: "请输入商品分类名称", trigger: "blur" }]
      },
      ruleForm: {
        parentId: null,
        name: "",
        order: 1, //排序
        icon: ""
      },
      checkList: [],
      selectList: [],
      showImgs: false
    };
  },
  watch: {
    form: {
      handler() {
        this.ruleForm = this.form;
      },
      deep: true
    }
  },
  created() {
    this.ruleForm = this.form;
    // console.log(this.ruleForm);
  },
  mounted() {
    // this.ruleForm = this.form;
    // console.log('mounted',this.ruleForm)
  },
  methods: {
    submitForm() {
      this.$refs["formCheck"].validate(valid => {
        if (valid) {
          this.submit();
        }
      });
    },
    submit: _.debounce(async function() {
      //编辑
      if (this.ruleForm.id) {
        const param = this.ruleForm;
        // console.log(this.ruleForm);
        orgcatEdit(param).then(res => {
          console.log(res);
          this.$message.success("修改成功");
          this.hide();
        })
        .catch(err => {
          this.$message.error(err.message);
        });
      } else {
        const param = this.ruleForm;
        orgcatAdd(param).then(res => {
          console.log(res);
          this.$message.success("添加成功");
          this.hide();
        }).catch(err => {
          this.$message.error(err.message);
        });
      }
    }, 1000),
    hide(type) {
      this.$emit("hide", type);
    }
  }
};
</script>
<style scoped>
.list > div {
  padding: 5px 0;
}
</style>
